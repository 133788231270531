import {
  IDelivery,
  IDeliveryDependencies,
  IDisciplineData,
  IKeypoint,
  IMainProcess,
  IMilestone,
  IProject,
  ISingleUser,
  ITaskData,
  IUserData,
  IdempotencyResponse,
  ICreateDelivery,
  IUpdateDelivery,
  ICreateKeyPoint,
  IUpdateKeyPoint,
  IFreezePeriod,
} from '../interface/org'
import { Card, CardTaskStatus } from '@mirohq/websdk-types'
import { uniqueIds } from '../util/const/util'
import BaseService from './BaseService'

interface TaskMeta {
  discipline_id: number
  responsible_id: number
  project_id: number
}

class OrgService extends BaseService {
  constructor() {
    super(true)
  }

  public getOwnUser = (): Promise<{ user: IUserData }> => {
    this.init()
    return this.get<{ user: IUserData }>(`user`)
  }

  public getProjects = async (userId: number) => {
    const data = await this.get<{ project: IProject }[]>(
      `/users/${userId}/projects/`,
    )

    return data.map((d: { project: IProject }) => d.project)
  }

  public getDisciplineUsers = async (discipline: number, projectId: number) => {
    const data = await this.get<ISingleUser[]>(
      `/disciplines/${discipline}/users/`,
      projectId,
    )

    return data
  }

  public getUserDisciplines = async (
    projectId: number,
  ): Promise<IDisciplineData[]> => {
    return this.get(`/projects/${projectId}/disciplines`)
  }

  public getUserDiscipline = async (
    disciplineId: number,
    projectId: number,
  ): Promise<IDisciplineData> => {
    return this.get(`disciplines/${disciplineId}`, projectId)
  }

  public getProjectKeypoints = async (
    projectId: number,
  ): Promise<IKeypoint[]> => {
    return this.get(`/projects/${projectId}/key_points`)
  }

  public getProjectMilestones = async (
    projectId: number,
  ): Promise<IMilestone[]> => {
    return this.get(`/projects/${projectId}/mile_stones`)
  }

  public getProjectUsers = async (
    projectId: number,
  ): Promise<ISingleUser[]> => {
    return this.get(`/projects/${projectId}/users`)
  }

  public getProjectPlanFreezePeriod = (
    projectId: number,
    dataType: string,
  ): Promise<IFreezePeriod> => {
    return this.get(`projects/${projectId}/${dataType}/plan_freeze_period`)
  }

  public getProjectTasks = async (projectId: number) => {
    const data = await this.get<ITaskData[]>(
      `parents/Project/${projectId}/tasks`,
    )

    return data
  }

  public getProjectMainProcess = async (projectId: number) => {
    const data = await this.get<IMainProcess[]>(
      `/projects/${projectId}/main_processes`,
    )

    return data
  }

  public updateDeliveries = async (
    deliveries: IUpdateDelivery[],
    project_id: number,
  ): Promise<IdempotencyResponse> => {
    return await this.post(`projects/${project_id}/deliveries/mass_update`, {
      deliveries: deliveries.map((delivery) => ({
        ...delivery,
        project_id,
        status: 'open',
      })),
    })
  }

  public createDeliveries = async (
    deliveries: ICreateDelivery[],
    project_id: number,
  ): Promise<IdempotencyResponse> => {
    return await this.post(`projects/${project_id}/deliveries/mass_create`, {
      deliveries: deliveries.map((delivery) => ({
        ...delivery,
        project_id,
        status: 'open',
      })),
    })
  }

  public createKeypoints = async (
    keypoints: ICreateKeyPoint[],
    project_id: number,
  ): Promise<IdempotencyResponse> => {
    return await this.post(`projects/${project_id}/key_points/mass_create`, {
      key_points: keypoints.map((keyPoint) => ({
        ...keyPoint,
        project_id,
        status: 'open',
      })),
    })
  }

  public updateKeypoints = async (
    keypoints: IUpdateKeyPoint[],
    project_id: number,
  ): Promise<IdempotencyResponse> => {
    return await this.post(`projects/${project_id}/key_points/mass_update`, {
      key_points: keypoints.map((keypoint) => ({
        ...keypoint,
        project_id,
        status: 'open',
      })),
    })
  }

  public createDeliveryDependencies = async (
    dependencies: Record<number, number[]>,
    projectId: number,
  ) => {
    await Promise.all(
      Object.entries(dependencies).map(([item, deps]) =>
        this.addDependentDelivery(Number(item), deps, projectId),
      ),
    )
  }

  public createKeypointDependencies = async (
    dependencies: Record<number, number[]>,
    projectId: number,
  ) => {
    await Promise.all(
      Object.entries(dependencies).map(([item, deps]) =>
        this.addDependentKeypoint(Number(item), deps, projectId),
      ),
    )
  }

  public addDependentDelivery = async (
    id: number,
    ids: number[],
    projectId: number,
  ) => {
    const data = await this.put(
      `/deliveries/${id}/reset_delivery_dependencies`,
      { dependent_delivery: uniqueIds(ids) },
      projectId,
    )
    return data
  }

  public addDependentKeypoint = async (
    id: number,
    ids: number[],
    projectId: number,
  ) => {
    const data = await this.put(
      `/key_points/${id}/reset_key_point_dependencies`,
      { dependent_on_key_point: uniqueIds(ids) },
      projectId,
    )
    return data
  }

  public pullDeliveries = async (
    projectId: number,
    endTime: string[],
    discipline: number[],
  ): Promise<IDelivery[]> => {
    const data = await this.post(
      `/projects/${projectId}/deliveries_filter/pages/0/0`,
      {
        discipline,
        endTime,
      },
    )
    return data
  }

  public pullKeypoints = async (
    projectId: number,
    endTime: string[],
    discipline: number[],
  ): Promise<IKeypoint[]> => {
    const data = await this.post(
      `/projects/${projectId}/key_points/pages/0/0`,
      {
        discipline,
        endTime,
      },
    )
    return data.items
  }

  public deliveryDependencies = async (
    projectId: number,
    endTime: string[],
    discipline: number[],
  ): Promise<IDeliveryDependencies[]> => {
    const data = await this.post(
      `/projects/${projectId}/delivery_discipline_canvas`,
      {
        discipline,
        endTime,
      },
    )
    return data.items.map((item: IDeliveryDependencies) => ({
      id: item.id,
      dependent_on_ids: item.dependent_on_ids,
      dependent_ids: item.dependent_ids,
    }))
  }

  public keypointDependencies = async (
    projectId: number,
    endTime: string[],
    discipline: number[],
  ): Promise<IDeliveryDependencies[]> => {
    const data = await this.post(
      `/projects/${projectId}/key_point_discipline_canvas`,
      {
        discipline,
        endTime,
      },
    )
    return data.items.map((item: IDeliveryDependencies) => ({
      id: item.id,
      dependent_on_ids: item.dependent_on_ids,
      dependent_ids: item.dependent_ids,
    }))
  }

  public saveTask = async (cards: Card[], meta: TaskMeta) => {
    const { discipline_id, responsible_id, project_id } = meta

    const status = (stat: CardTaskStatus) => {
      switch (stat) {
        case 'to-do':
        case 'in-progress':
          return 'in_progress'
        case 'done':
          return 'done'
        default:
          return 'in_progress'
      }
    }

    for (const card of cards) {
      const task = {
        title: card.title,
        description: card.description,
        discipline_id,
        project_id,
        parent_id: project_id,
        deadline: card.dueDate,
        responsible_id,
        status: status(card.taskStatus),
        taskType: 'Aksjon',
        parent_type: 'Project',
      }

      await this.post(`/tasks`, task)
    }
  }
}

export default new OrgService()
